import React, { useState ,useCallback} from 'react';
import { Row, Col,Button,Space } from 'antd';
import TweenOne,{ TweenOneGroup } from 'rc-tween-one';
import ScrollOverPack from 'rc-scroll-anim/lib/ScrollOverPack';
import QueueAnim from 'rc-queue-anim';
import "antd/dist/antd.css";
import './App.css';
import etudiant from './etudiant.svg'
import locataire from './locataire.svg'

import acheteur from './acheteur.svg'
import Dossier from './form_dossier.js'
import {useParams} from "react-router-dom";
const page1 = [
  {
    title: 'Étudiant',
    link: `/docs/download`,
    src: etudiant,
  },
  {
    title: 'Locataire',
    link: `/docs/download`,
    src: locataire,
  },
  {
    title: 'Acquereur',
    link: `/docs/guide/layout`,
    src: acheteur,
  },
];

const pointPos = [
  { x: -90, y: -20 },
  { x: 35, y: -25 },
  { x: -120, y: 125 },
  { x: -100, y: 165 },
  { x: 95, y: -5 },
  { x: 90, y: 160, opacity: 0.2 },
  { x: 110, y: 50 },
];




export default function Choix1(props) {
	
const [hoverNum,setHover] = useState(null);
const [selNum,setSel] = useState(null);
let {uid}  = useParams();
const [start,setStart] = useState(uid?false:true);
const [dossier, setDossier] = useState(uid?-1:null);

const fermerDossier = useCallback((t) => {setSel(null);setDossier(null);setStart(true)}, []);

const getEnter = (e) => {
    const i = e.index;
    const r = (Math.random() * 2) - 1;
    const y = (Math.random() * 10) + 5;
    const delay = Math.round(Math.random() * (i * 30));
    return [
      {
        delay, opacity: 0.4, ...pointPos[e.index], ease: 'easeOutBack', duration: 300,
      },
      {
        y: r > 0 ? `+=${y}` : `-=${y}`,
        duration: (Math.random() * 1000) + 2000,
        yoyo: true,
        repeat: -1,
      }];
  }

 const getChildrenToRender = () => {

    return page1.map((item, i) => {
		
		

      const pointChild = [
        'point-ring left', 'point-ring point-ring-0 right',
        'point-0', 'point-2', 'point-1', 'point-3', 'point-2',
      ].map((className, ii) => (
        <i
          className={className}
          key={ii.toString()}
        />
      ));
	  
	 
      return (
        <Col md={page1.length===2?12:8} xs={24} key={i.toString()} className="page1-item">
          <button style={{filter:selNum!=null && i!==selNum ?'saturate(0)':'none'}} 
		  onClick={()=>setDossier(i)} className="page1-item-link" onMouseEnter={()=>setHover(i)} onMouseLeave={()=>setHover(null)} >
            <div className="page1-item-img">
            <TweenOneGroup
              enter={getEnter}
              leave={{
                x: 0, y: 30, opacity: 0, duration: 300, ease: 'easeInBack',
              }}
              resetStyle={false} exclusive className="point-wrapper" >
              {(hoverNum === i || selNum===i) && pointChild}
            </TweenOneGroup>
              <img style={{width:'52%'}} src={item.src} alt="img" />
            </div>
            <div className="page1-item-title">
              {item.title}
            </div>
          </button>
        </Col>
      );
    });
  }
console.log('uid',uid)
 const children = getChildrenToRender();
 
 
  if(start)return    <div id="page1" className="content-wrapper page">
      <TweenOne
	  
        key="image"
        className="image1 image-wrapper"
        animation={{ x: 0, opacity: 1, ease: 'easeOutQuad' }}
        style={{ transform: 'translateX(-100px)', opacity: 0 }}
      >
	 
	  </TweenOne>
      <QueueAnim
        type={false ? 'bottom' : 'right'}
        className="text-wrapper"
        key="text" delay={500} 
        leaveReverse
      >
        <h2 key="h2" className="hh2">Mon dossier en ligne</h2>
        <p key="p" >Créez votre dossier client en ligne en quelques clics.<br/>
Toutes les informations fournies restent confidentielles </p>
        <div key="button">
          <a>
            <Button onClick={()=>setStart()} type="primary" size="large">
              Créer mon dossier
            </Button>
          </a>
        </div>
      </QueueAnim>
    </div>

	if(dossier!=null)
		return <Dossier onCancel={()=>fermerDossier()} uid={uid} type={dossier}/>

    return (<div className="home-page-wrapper page1">
        <div className="home-page">
          <h1>
           Mon dossier en ligne
          </h1>
          <div className="page1-content">
		  {page1.length===2?
		  <Row><Col md={{span:16,offset:4}} sm={{span:24,offset:0}}>
            <QueueAnim key="queue" type="bottom" leaveReverse component={Row}>
              {children}
            </QueueAnim>
		  </Col></Row>
		  :
		  <QueueAnim key="queue" type="bottom" leaveReverse component={Row}>
              {children}
            </QueueAnim>
		  }
          </div>
		  <div style={{marginTop:32,textAlign:'center'}}>
		  {selNum!=null?<QueueAnim type={['bottom']} >
			<Space wrap key="a">

				<Button type="primary" onClick={()=>setDossier(selNum)} size="large">Créer mon dossier</Button>
			</Space>
		  </QueueAnim>:null}
		  </div>
        </div>
      </div>
    );
}
