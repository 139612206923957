import React, { useState } from 'react';
import { AutoComplete,Input } from 'antd';

export default function Metier(props){
  const [result, setResult] = useState([]);

  const handleSearch = (value) => {


const urlroot="https://dossier.lescalegestion.fr/"
		fetch(urlroot+"api/metier.php?s="+value).then(res => res.json()).then(
			(result) => {
				setResult(result)
		});

  };

  return (
    <AutoComplete
	{...props} dropdownMatchSelectWidth={false}
      onSearch={handleSearch} 
	  options={result.map((v) =>{return {key:v,value:v}})}
    >
	<Input />

    </AutoComplete>
  );
};

