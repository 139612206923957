import React, { useState ,useRef,useEffect,useCallback} from 'react';
import {Checkbox,Empty,Modal,Divider,Select, Result,Input,Button,Row,Col,Grid,Popconfirm,Alert,Card,Spin,PageHeader,Menu,Carousel,Radio,Form,DatePicker } from 'antd';
import Upload2 from './upload.js';
import {DownloadOutlined,CloseOutlined,PlusOutlined,RightOutlined,LeftOutlined,CheckOutlined} from "@ant-design/icons";
import QueueAnim from 'rc-queue-anim';
import moment from 'moment';
import 'moment/locale/fr'; 
//import {useHistory} from "react-router-dom";
import DemarrageDossier from './demrrage_dossier - reunion.js';
import Metier from './metier.js';
import VilleCP from './villecp.js';
import PDF from './pdf.js';
import locale from 'antd/es/locale/fr_FR'
import get_form from './form_def reunion.js'
const { useBreakpoint } = Grid;
const { Option } = Select;
const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 12 },
};

export default function Dossier(props) {
	
	const [error, setError] = useState(null);

	const [fields, setFields] = useState({garant_oui:'Non',etudiant:'Non',type_dossier:props.type});
	const [recap, setRecap] = useState(null);
	const screens = useBreakpoint();
	const [finished, setFinish] = useState(null);
	const [loading, setLoading] = useState(props.uid!==undefined);
	const [demarrer, setStart] = useState(false);
	const [tab, setTab] = useState('0');
	const slider = useRef(null);
	const [form_] = Form.useForm();
	const [preview,setPrv] = useState(null);
	//let history = useHistory();
	 
	const fermerDemarrage = useCallback(() => {setStart(true)}, []);

	const query = (action,post,onok,onerr,always) => {

	fetch("https://resa.nextreunion.re/upload.php?"+action,
			post?{method: 'POST',credentials: "include",body: JSON.stringify(post)} :{credentials: "include"}) 
		.then(res => res.json())
		.then((result) => {
			
			if(result.erreur!==undefined){
				if(onerr)onerr(result)
			}
			else if(onok)onok(result)
			
			if(always)always(result)
		},
		(error) => {
			if(onerr)onerr(error)
			if(always)always(error)
		}
	  )
	}
	
	const type_dossier = props.uid===undefined?props.type:fields&&fields.type_dossier

	const load = useCallback((uid) => {
		
		setError(null);
		setLoading(true);
		query('get='+uid, null,
		(ok)=>{
			if(ok.naissance!==undefined)
				ok.naissance = moment(ok.naissance,'YYYY-MM-DD');

			if(ok.garant_naissance!==undefined)
				ok.garant_naissance = moment(ok.garant_naissance,'YYYY-MM-DD');

			setFields(ok);console.log(ok)},
		(err)=>setError(<Alert style={{marginBottom:20}} message="Erreur" type="error" 
							description={err.erreur?err.erreur:"Erreur lors du chargement"} 
							action={<Button onClick={()=>{load(uid)}}>Reessayer</Button>} 
							showIcon />),
		(always)=>setLoading(false))
	},[])


	useEffect(()=>{if(props.uid!==undefined)load(props.uid)},[load,props.uid])
	

	const formItem=({comp,type,suffix,option, ...itmprops}, k) => {

		const uploadprops = {
			children:<div><PlusOutlined /><div style={{marginTop:8}}>Ajouter</div></div>,
			withCredentials:true,
			locale:locale.Upload,		
			action:"https://resa.nextreunion.re/upload.php",
			listType:"picture-card",
			multiple:true,
			accept:".jpg,.jpeg,image/png,.pdf,image/*;capture=camera"
		}
		
		if(itmprops.name===undefined)return <br key={k}/>
		if(itmprops.disabled===true)return
		
		let child = null;
		if(type==="upload") {
			itmprops.valuePropName="fileList"
			itmprops.getValueFromEvent=normFile
			child = <Upload2 {...uploadprops}/>
		}
		if(type==="checkbox")
			child = <Checkbox.Group>{option.map((o,i)=> <Checkbox key={i} value={o}>{o}</Checkbox>)}</Checkbox.Group>
		if(type==="radio")
			child = <Radio.Group>{option.map((o,i)=> <Radio key={i} value={o}>{o}</Radio>)}</Radio.Group>
		if(type==="select1")
			child = <Select>{option.map((o,i)=> <Option key={i} value={o}>{o}</Option>)}</Select>
		if(type==="select")
			child = <Select mode="tags">{option.map((o,i)=> <Option key={i} value={o}>{o}</Option>)}</Select>
		if(type==="input")
			child = <Input suffix={suffix}/>
		if(type==="date")
			child = <DatePicker locale={locale.DatePicker} style={{width:200}} format="DD/MM/YYYY" />
		if(type==="metier")
			child = <Metier />
		if(type==="villecp")
			child = <VilleCP />
		
		
		return <Form.Item key={k} {...itmprops}>{child?child:comp}</Form.Item>
	}
	

	 
	useEffect(()=>{
		if(!form_)return;
		
		const v = form_.getFieldsValue();
		setRecap(v);
		//console.log(v)
	},[tab,form_]);
	
	const normFile = (e) => {
		slider.current.innerSlider.adaptHeight()
		if (Array.isArray(e)) {
		return e;
		}	

		return e && e.fileList
	};
	
	const tabs = get_form(fields.type_dossier, fields);
	if(tabs){	
		var recaparr=recap&&tabs.map((o,i)=>o.disabled&&o.comp===undefined?null:
		<div key={i}>
			<Divider style={{color:'#ffb400'}} orientation="left">{o.nom}</Divider>
			<div className="tablerecap">
				{o.item.map(
				(w,i)=>{
					if(w.name===undefined ||w.disabled===true)return null;
					var val = recap[w.name]
					if(typeof val === 'object') {
						if(val._isAMomentObject)
							val = val.format('DD/MM/YYYY')
						else if(Array.isArray(val))
							val = val.map(p=>{
									if(typeof p==="string")return p+' ';
								if(p.status!==undefined&&p.status==='done'){
									var ch = <Empty description="Pas d'apperçu disponnible" />
									if(p.type.substring(0, 5)==='image')
										ch = <img alt="" style={{width:'100%'}} src={p.url}/>
									else if(p.type==="application/pdf")
										ch=	  <PDF file={p.url} />
									const prv = <Modal footer={<Button target="_blank" href={p.url} type="primary"><DownloadOutlined/> Télécharger</Button>} title={p.name} visible onCancel={()=>setPrv(null)}> {ch} </Modal>
									return <div><Button target="_blank" type="link" onClick={()=>setPrv(prv)}>{p.name}</Button></div>;
								}
								return null;
							}).filter(q=>q!=null)
						else val=null
					}
					const requi = w.rules&&w.rules[0].required===true
					if(val==null||val===undefined){
						val = requi?<i style={{color:'#ff4d4f'}}>à compléter</i>:<i style={{color:'#888'}}>vide</i>
					}
					return <div key={i}><div>{w.label}{requi?<span style={{color:'#ff4d4f'}}> *</span>:null}</div><div>{val}</div></div>
				}).filter(y=>y!=null)}
			</div>
		</div>).filter(y=>y!=null)
	
	tabs.push({
			nom:'Validation',
		comp:<>{recaparr}<p>Une fois enregistré, vous recevrez, par e-mail, un lien permettant de consulter ou modifier votre dossier</p>{error}</>
		})
}

	const change=(row)=>{
		row.forEach(o=>{
				if(o.name[0]==="garant_oui")
					setFields({...fields, garant_oui:o.value})
				else if(o.name[0]==="financement")
					setFields({...fields, financement:o.value})
				else if(o.name[0]==="etudiant")
					setFields({...fields, etudiant:o.value})
		})	
	}
	const save=(row)=>
	{	
		setError(null)
		setLoading(true)
		
		var momentobj=[];
		
		for(var k in row)
		{
			const o=row[k];
			if(typeof o === 'object' && o!=null) {
				if(o._isAMomentObject){
					row[k] = o.format('YYYY-MM-DD')
					momentobj.push(k);
				}
				else if(Array.isArray(o))
					row[k] = o.map(p=>{
						
						if(p && p.uid!==undefined){
							if(p.status==='done'){
								return (p.response!==undefined)?{...p.response,type:p.type,uid:p.uid}:p; 
							}
							return null;
						}
						return p;
					}).filter(q=>q!=null)
			}

		}
		console.log(row)
		
		row.uid=props.uid;
		if(row.type_dossier===undefined)
			row.type_dossier = type_dossier;
		
	
row.tableau_recap=recap&&get_form(row.type_dossier, row).map((o,i)=>o.disabled&&o.comp===undefined?null:{nom:o.nom, itms:o.item.map(
				(w,i)=>{
					if(w.name===undefined ||w.disabled===true)return null;
					var val = w.name
					return {label:w.label, type:w.type, val}
				}).filter(y=>y!=null)}).filter(y=>y!=null)


		fetch("https://resa.nextreunion.re/upload.php?save",{method: 'POST',credentials: "include",body: JSON.stringify(row)}) 
		.then(res => res.json())
		.then((result) => {
			
			setLoading(false);
			
			if(result.erreur!==undefined)
				setError(<Alert style={{marginBottom:20}} message="Erreur" type="error" description={result.erreur} 
						action={null} showIcon />)
						
			else {
				setFinish(<Result status="success" title="Dossier enregistré avec succès"
    subTitle={<>Un e-mail de confirmation vous a été envoyé, votre dossier sera traité sous 48H<br/> passé ce delai, n&apos;hésitez pas à reprendre contact avec nous</>}
    extra={<>
	<Button onClick={()=>{setTab('0');setFinish(null)}}>Consulter votre dossier</Button>
		{!props.uid?<Button onClick={()=>{props.onCancel()}} type="primary">Fermer</Button>:null}
	</>}
  />);
  console.log(momentobj);
	momentobj.forEach(o=>result[o] = moment(result.naissance,'YYYY-MM-DD'));
form_.setFieldsValue(result);
			}
			
		},
		(error) => {
			setError(<Alert style={{marginBottom:20}} message="Erreur" type="error" description="Erreur lors du chargement" 
			action={<Button onClick={()=>{save(row)}}>Reessayer</Button>} showIcon />)
			
			setLoading(false);
		}
	  )
		
	}

	const suppr=()=>
	{	
		setError(null)
		setLoading(true)

		fetch("https://resa.nextreunion.re/upload.php?suppr="+props.uid,{credentials: "include"}) 
		.then(res => res.json())
		.then((result) => {
			
			setLoading(false);
			if(result.logged===false)return
			
			if(result.erreur!==undefined)
				setError(<Alert style={{marginBottom:20}} message="Erreur" type="error" description={result.erreur} 
						action={<Button onClick={()=>{suppr()}}>Reessayer</Button>} showIcon />)
						
			else {
				
				setFinish(<Result status="info" title="Dossier supprimé"
    subTitle="Votre dossier a bien été supprimé"
    extra={
	<Button onClick={()=>props.onCancel()} type="primary">Fermer</Button>
	}
  />);
			}
			
		},
		(error) => {
			setError(<Alert style={{marginBottom:20}} message="Erreur" type="error" description="Erreur lors du chargement" 
			action={<Button onClick={()=>{suppr()}}>Reessayer</Button>} showIcon />)
			
			setLoading(false);
		}
	  )
		
	}

	const onMove=(move)=>
	{
		let k =parseInt( tab)+move;
		if(k<0)k=tabs.length-1
		if(k>tabs.length-1)k=0
		slider.current.goTo(k)
	}
	var childs=null
	if(finished!=null){
		childs =  finished;
	}
	else if(demarrer===false &&props.uid===undefined)
		childs = <DemarrageDossier start={fermerDemarrage} type={type_dossier}/>
	else if(props.uid!==undefined && error!=null)
		childs = error
	else if(props.uid!==undefined && loading)
		childs = <div style={{textAlign:'center',padding:50}}><Spin tip="Chargement..."/></div>

	else childs = <Row gutter={[20,0]} >

				<Col xs={24} sm={24} md={8}  lg={6} >
				
				{screens.xs===true||(screens.sm===true&&screens.md===false)?
					<><Divider>{parseInt(tab)+1}. {tabs.filter(o=>!o.disabled).filter((z,i)=>tab===i.toString())[0].nom}</Divider><br/></>:
							<QueueAnim key="menu" delay={50} type="left" style={{height:'100%',width: '100%'}} component={Menu} 
							onClick={({key})=>slider.current.goTo(key)} selectedKeys={tab} mode="inline"  >
  
			{tabs.filter(o=>!o.disabled).map((o,i)=> <Menu.Item disabled={loading} key={i}>{i+1}. {o.nom}</Menu.Item>)}
			</QueueAnim>
				}
				</Col>
				<Col  xs={24} sm={24} md={16} lg={{ span: 16, offset: 1 }}>
			<Spin tip="Chargement..." spinning={loading}>
<Form onFieldsChange={(r)=>change(r)} key="frm" form={form_} {...layout} name="dossier" initialValues={fields} onFinish={(r)=>save(r)} 
	onFinishFailed = {(a)=>{
		setError(<Alert style={{marginBottom:20}} message="Formulaire incomplet" type="warning" 
		description="Tous les champs précédés d'une étoile sont obligatoires" showIcon />)
		
	}}>
					<Carousel adaptiveHeight effect='fade' dots={false} infinite={false} beforeChange={(a,b)=>{
						setTab(b.toString())
						if(b===(tabs.length-1))setError(null)
						// slider.current.innerSlider.list.scrollIntoView()
					}} ref={(ref)=>{slider.current=ref}}>
						{tabs.map((o,i)=>o.disabled?null:<div key={i} style={{padding:'20px 1px'}}>
							{o.comp===undefined?o.item.map((w,i)=>formItem(w,i)):o.comp}
							<br/>
							<Row justify="space-between">
							{i===0?<i/>:<Button onClick={()=>onMove(-1)}><LeftOutlined style={{color:"#666"}} /> Précédent</Button>}
							{i!==(tabs.length-1)?<Button onClick={()=>onMove(+1)}>Suivant <RightOutlined style={{color:"#666"}} /></Button>:
							<Button type="primary" htmlType="submit" icon={<CheckOutlined />}>{props.uid!=null?'Modifier':'Enregistrer'}</Button>}
							</Row><br/>
						</div>)}
					</Carousel>
					</Form>
		
	
		  </Spin>
		 </Col>
			</Row>

    return (<div className="home-page" style={{paddingTop:64}}>
 <Card style={{position:'relative', height:'100%', width:'100%'}} 
 title={<PageHeader title={<div><span style={{color:'#ffda00'}}>›</span> Mon dossier</div>} extra={finished!=null?null:<>
	 {props.uid==null?null:<Popconfirm placement="bottomRight" title="Supprimer définitivement ?" onConfirm={()=>suppr()} okText="Oui" cancelText="Non">
			<Button type="text" size="small" style={{color:'#888'}} >supprimer mon dossier</Button>
	 </Popconfirm>}
	       <Popconfirm placement="bottomRight" title="Annuler ?"
			onConfirm={()=>props.onCancel()} okText="Oui" cancelText="Non">
				{!props.uid?<Button style={{color:'#888'}} icon={<CloseOutlined/>} type="text" />:null}
      </Popconfirm>
	  </>
	 }
 footer={finished!=null?null:<span style={{color:'#888',fontWeight:'normal'}}>Completez le formulaire afin d'enregistrer votre dossier</span>}
 />}>
 {preview}
			{childs}
			
		</Card>
	</div>
	);
}

