import React, { useState } from 'react';
import { AutoComplete,Input } from 'antd';

const VilleCP = (props) => {
  const [result, setResult] = useState([]);

  const handleSearch = (value) => {


const urlroot="https://dossier.lescalegestion.fr/"
		fetch(urlroot+"api/villecp.php?cpville="+value).then(res => res.json()).then(
			(result) => {
			
				let res = result.map(v=>v.cp+' '+v.ville)
				setResult(res)
		});

   
  };

  return (
    <AutoComplete value={props.value}
	id={props.id} onChange={props.onChange}
      onSearch={handleSearch} 
      placeholder="Code postal ou ville"
	  options={result.map((v) =>{return {key:v,value:v}})}
    >
	<Input />

    </AutoComplete>
  );
};

export default VilleCP