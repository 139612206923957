import React, { useState } from 'react';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';
import { Button } from 'antd';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import { RightOutlined ,LeftOutlined} from "@ant-design/icons";

const options = {
  cMapUrl: 'cmaps/',
  cMapPacked: true,
};

export default function PDF(props) {
  const [numPages, setNumPages] = useState(null);
  const [cur, setCur] = useState(1);


  function onDocumentLoadSuccess({ numPages: nextNumPages }) {
    setNumPages(nextNumPages);
  }

  return <>
  <Document loading="Chargement du document..." error="Erreur de chargement" onLoadSuccess={onDocumentLoadSuccess} {...props}>
	<Page loading="Chargement de la page..."  width="472" pageNumber={cur}/></Document>
  
  {numPages&&numPages>1?Array.from(new Array(numPages),(e,i) => <Button style={{margin:2}} disabled={cur===i+1} onClick={()=>setCur(i+1)}>Page {i+1}</Button>):null}
			
  </>
}