import {Button,Row,Col, } from 'antd';
import QueueAnim from 'rc-queue-anim';



export default function DemarrageDossier(props)
{
	const commencer = <div key="start" style={{textAlign:'center'}}><Button type="primary" onClick={()=>props.start()}>Commencer </Button></div>
	if(props.type===0)
		return <Row gutter={[20,20]} ><Col  xs={24} sm={24} md={{ span: 18, offset: 3 }} lg={{ span: 16, offset: 4 }}>
			<QueueAnim key="startpage" type="bottom">
		<p style={{fontSize:'15px'}}>	<br/>Nous allons vous aider dans le montage de votre dossier immobilier afin de pouvoir réaliser votre
réservation sur un de nos logements.
<br/><br/>
En tant qu’étudiant vous devez faire appel à un garant puisque vous n’avez pas de revenus.
<br/><br/>
Le garant doit être en contrat à durée indéterminé, chef d’entreprise ou à la retraite.
<br/><br/>
Ces revenus mensuels doivent faire 3 fois le montant du loyer.
<br/><br/>
Une liste de documents concernant votre garant et vous-même vous sera demandé lors du montage
de ce dossier.
<br/><br/>
Vos données sont privées et reste confidentielles sur notre site.
</p>
<br/><br/>
{commencer}
</QueueAnim>
</Col>
</Row>
	if(props.type===1)
		return <Row gutter={[20,20]} ><Col  xs={24} sm={24} md={{ span: 18, offset: 3 }} lg={{ span: 16, offset: 4 }}>
			<QueueAnim key="startpage" type="bottom">
		<p style={{fontSize:'15px'}}>	<br/>Nous allons vous aider dans le montage de votre dossier immobilier afin de pouvoir réaliser votre
réservation sur un de nos logements.
<br/><br/>
Vous ou votre garant devez être en contrat à durée indéterminé, chef d’entreprise ou à la retraite.
<br/><br/>
Les revenus mensuels doivent faire 3 fois le montant du loyer.
<br/><br/>
Une liste de documents concernant votre garant et vous-même vous sera demandé
<br/><br/>
Vos données sont privées et reste confidentielles sur notre site.
</p>
<br/><br/>
{commencer}
</QueueAnim>
</Col>
</Row>

props.start()
return null

}