import React, { useState} from 'react';
import {Empty, Upload,Modal,Button } from 'antd';
import { DownloadOutlined } from "@ant-design/icons";
import PDF from './pdf.js';

export default function Upload2(props)
{
  const [list,setList] = useState(props.fileList);
  const [preview,setPrv] = useState(null);
  
  

const handlePreview = info => {

	var ch = null

	if(info.type.substring(0, 5)==='image' && info.url)
		ch = <img style={{width:'100%'}} src={info.url}/>
	else if(info.type==="application/pdf" && info.url)
		ch=	  <PDF file={info.url} />

	setPrv(<Modal footer={info.url?<Button target="_blank" href={info.url} type="primary"><DownloadOutlined/> Télécharger</Button>:null} title={info.name} visible onCancel={()=>setPrv(null)}> 
	{ch==null?<Empty description="Pas d'apperçu disponnible" />:ch}
	</Modal>);
}
const handleChange = info => {
	let fileList = [...info.fileList];

	fileList = fileList.map(file => {
		if (file.response) {
			file.url = file.response.url;
			file.status = file.response.status=='done'?'done':'error'
		}
		
		
		return file;
	});
if(!props.multiple)fileList = [fileList[fileList.length-1]];
    setList(fileList);
	props.onChange(info)
  };
    return <>{preview}<Upload {...props} onPreview={handlePreview} onChange={handleChange} fileList={list}/></>

}