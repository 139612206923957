
export default function get_form(type_dossier, fields)
{
	const list_finan = ["sans prêt immobilier","prêt avec apport","prêt sans apport"]
	const requi = [{required:true, message: 'Champ obligatoire' }]
	const requi2 = [{required:true, message: 'Document obligatoire' }]
	const pasdegarant=fields.garant_oui==='Non';
	const financement=list_finan.indexOf(fields.financement);
	
	var type_dossier_arr=[];
	type_dossier_arr[2] = [
		{
			nom:'Identité',
			item:[
				{type:'input',label:'Nom',name:'nom', rules:requi},
				{type:'input',label:'Prénom',name:'prenom', rules:requi},
				{},
				{type:'input',label:'E-mail',name:'email', rules:requi},
				{type:'input',label:'Téléphone',name:'telephone', rules:requi},
			]
		},
		{
			nom:'Critères de recherche',
			item:[
				{type:'select',label:'Type',name:'type', rules:requi,option:["Maison","Appartement","Immeuble","Terrain","Autre"]},
				{type:'input',suffix:"m²",label:'Surface',name:'surface', rules:requi},
				{type:'select',label:'Extérieur',name:'exterieur', rules:requi,option:["Terrasse","Jardin","Piscine"]},
				{type:'input',suffix:"€",label:'Budget',name:'budget', rules:requi},
				{type:'input',label:'Secteur géogaphique',name:'secteur', rules:requi},
			],
		},
		{
			nom:'Financement',
			item:[
				{type:'radio',label:'Moyen de financement',name:'financement',option:list_finan},
				{},
				{type:'input',disabled:financement===0,label:'Prime',name:'prime'},
				{type:'select1',disabled:financement===0,label:'Situation professionnel',name:'situation',option:["Contrat CDI","Contrat CDD","Sans emploi","Retraité","Autre"]},
				{type:'metier',disabled:financement===0,label:'Profession',name:'profession'},
				{type:'input',disabled:financement===0,label:'Salaire net mensuel',extra:"hors primes/revenus non salarié",name:'salaire'},
				{disabled:financement!==0},
				{type:'input',suffix:"€",disabled:financement!==1,label:'Apport',name:'apport'},
				{type:'input',suffix:"€",disabled:financement===0,label:'Montant du prêt',name:'pret'},
				{type:'input',suffix:"ans",disabled:financement===0,label:'Durée',name:'duree'},
				{type:'input',suffix:"%",disabled:financement===0,label:'Taux',name:'taux'},
				{type:'input',disabled:financement===0,label:'Banque',name:'banque'},
			],
		}
	]
	type_dossier_arr[0] = [{
			nom:'Mes informations',
			item:[
				{type:'input',label:'Nom',name:'nom', rules:requi},
				{type:'input',label:'Prénom',name:'prenom', rules:requi},
				{},
				{type:'date',label:'Date de naissance',name:'naissance', rules:requi},
				{type:'input',label:'Lieu de naissance',name:'lieu', rules:requi},
				{},
				{type:'input',label:'Adresse',name:'adresse', rules:requi},
				{type:'input',label:'Code postal',name:'cp', rules:[...requi, {pattern:/^[0-9]{5}$/, message: 'Code postal invalide'}]},
				{type:'input',label:'Ville',name:'ville', rules:requi},
				{},
				{type:'input',label:'E-mail',name:'email', rules:requi},
				{type:'input',label:'Téléphone portable',name:'portable', rules:requi},
				{type:'input',label:'Téléphone des parents',name:'tel_parents', rules:requi},
				 {},
				 {type:'input',label:'Études suivies',name:'etude', rules:requi},
					 {type:'radio',label:'Permis de conduire',name:'permis', rules:requi,option:['Oui','Non']},
				 {type:'radio',label:'Lieu d\'étude',name:'lieu_etude', rules:requi,option:["Saint-Denis","Saint-Pierre","Tampon"]},
			],
		},
		{
			nom:'Mon garant',
			item:[
				{type:'input',label:'Nom',name:'garant_nom', rules:requi},
				{type:'input',label:'Prénom',name:'garant_prenom', rules:requi},
				{},
				{type:'date',label:'Date de naissance',name:'garant_naissance', rules:requi},
				{type:'input',label:'Lieu de naissance',name:'garant_lieu', rules:requi},
				{},
				{type:'input',label:'Adresse',name:'garant_adresse', rules:requi},
				{type:'input',label:'Code postal',name:'garant_cp', rules:[...requi, {pattern:/^[0-9]{5}$/, message: 'Code postal invalide'}]},
				{type:'input',label:'Ville',name:'garant_ville', rules:requi},
				{},
				{type:'input',label:'E-mail',name:'garant_email', rules:requi},
				{type:'input',label:'Téléphone portable',name:'garant_portable', rules:requi},
				{type:'input',label:'Téléphone fixe',name:'garant_fixe'},
				{},
				{type:'metier',label:'Profession',name:'garant_profession'},
				{type:'input',label:'Entreprise',name:'garant_entreprise'},
				{type:'input',label:'Coordonnées de l\'entreprise',name:'garant_entreprise2'},
			],
		},
		{
			nom:'Documents à fournir',
			item:[
				{type:'upload',label:'Pièce d\'identité', extra:"carte d'identité ou passport", name:'identite', rules:requi2},
				{type:'upload',label:'R.I.B',name:'rib', extra:"à defaut, celui des parents", rules:requi2},
				{type:'upload',label:'Avis d\'impôt',name:'impot', extra:"à defaut, celui des parents", rules:requi2},
				{type:'upload', label:'Justificatif de domicile',extra:'quitance ou facture de moins de 3 mois',name:'dom', rules:requi2},
				{type:'upload', label:'Carte étudiante',name:'carte', rules:requi2, },
				{type:'upload', label:'Livret de famille',name:'livret', rules:requi2, },
				{type:'upload', label:'Résultats Baccalauréat, BTS',name:'diplome', },
			],
		},
		{
			nom:'Documents du garant',
			item:[
				{type:'upload',label:'Pièce d\'identité', extra:"carte d'identité ou passport", name:'garant_identite', rules:requi2},
				{type:'upload',label:'R.I.B',name:'garant_rib', rules:requi2},
				{type:'upload',label:'Avis d\'impôt',name:'garant_impot', rules:requi2},
				{type:'upload',label:'Justificatif de domicile',extra:'quitance ou facture de moins de 3 mois',name:'garant_dom', rules:requi2},
				{type:'upload',label:'Contrat de travail',extra:'à defaut, une attestation employeur de moins de 3 mois',name:'garant_contrat', rules:requi2},
				{type:'upload',label:'3 fiches de paies',name:'garant_paie',  rules:requi2},
				{type:'upload',label:'Si chef d\'entreprise',name:'garant_kbis', extra:'Extrait KBIS, Bilan, déclaration de revenu'},
			],
		}
	]
	type_dossier_arr[1] = [{
			nom:'Mes informations',
			item:[
				{type:'input',label:'Nom',name:'nom', rules:requi},
				{type:'input',label:'Prénom',name:'prenom', rules:requi},
				{},
				{type:'date',label:'Date de naissance',name:'naissance', rules:requi},
				{type:'input',label:'Lieu de naissance',name:'lieu', rules:requi},
				{},
				{type:'input',label:'Adresse',name:'adresse', rules:requi},
				{type:'input',label:'Code postal',name:'cp', rules:[...requi, {pattern:/^[0-9]{5}$/, message: 'Code postal invalide'}]},
				{type:'input',label:'Ville',name:'ville', rules:requi},
				{},
				{type:'input',label:'E-mail',name:'email', rules:requi},
				{type:'input',label:'Téléphone portable',name:'portable', rules:requi},
				{type:'input',label:'Téléphone fixe',name:'fixe'},
				{},
				{type:'input',label:'Profession',name:'profession'},
				{type:'input',label:'Entreprise',name:'entreprise'},
				{type:'input',label:'Coordonnées de l\'entreprise',name:'entreprise2'},
			],
		},
		{
			nom:'Mon garant',
			item:[
			{type:'radio',label:'J\'ai un garant', name:'garant_oui',rules:requi,option:["Oui","Non"]},
			{},
			{type:'input',disabled:pasdegarant,label:'Nom',name:'garant_nom', rules:requi},
			{type:'input',disabled:pasdegarant,label:'Prénom',name:'garant_prenom', rules:requi},
			{},
			{type:'date',disabled:pasdegarant,label:'Date de naissance',name:'garant_naissance', rules:requi},
			{type:'input',disabled:pasdegarant,label:'Lieu de naissance',name:'garant_lieu', rules:requi},
			{},
			{type:'input',disabled:pasdegarant,label:'Adresse',name:'garant_adresse', rules:requi},
			{type:'input',disabled:pasdegarant,label:'Code postal',name:'garant_cp', rules:[...requi, {pattern:/^[0-9]{5}$/, message: 'Code postal invalide'}]},
			{type:'input',disabled:pasdegarant,label:'Ville',name:'garant_ville', rules:requi},
			{},
			{type:'input',disabled:pasdegarant,label:'E-mail',name:'garant_email', rules:requi},
			{type:'input',disabled:pasdegarant,label:'Téléphone portable',name:'garant_portable', rules:requi},
			{type:'input',disabled:pasdegarant,label:'Téléphone fixe',name:'garant_fixe'},
			{},
			{type:'input',disabled:pasdegarant,label:'Profession',name:'garant_profession'},
			{type:'input',disabled:pasdegarant,label:'Entreprise',name:'garant_entreprise'},
			{type:'input',disabled:pasdegarant,label:'Coordonnées de l\'entreprise',name:'garant_entreprise2'},

			],
		},
		{
			nom:'Documents à fournir',
			item:[
			{type:'upload',label:'Pièce d\'identité', extra:"carte d'identité ou passport", name:'identite', rules:requi2},
			{type:'upload',label:'R.I.B',name:'rib', rules:requi2},
			{type:'upload',label:'Avis d\'impôt',name:'impot',rules:requi2},
			{type:'upload',label:'Justificatif de domicile',extra:'quitance ou facture de moins de 3 mois',name:'dom', rules:requi2},
			{type:'upload',label:'Contrat de travail',extra:'à defaut, une attestation employeur de moins de 3 mois',name:'contrat', rules:requi2},
			{type:'upload',label:'3 fiches de paies',name:'paie', rules:requi2},
			{type:'upload',label:'Si chef d\'entreprise',name:'kbis',extra:'Extrait KBIS, Bilan, déclaration de revenu'},

			],
			    
		},
		{
			nom:'Documents du garant',
			disabled: pasdegarant,
			item:[
				{type:'upload',label:'Pièce d\'identité', extra:"carte d'identité ou passport", name:'garant_identite', rules:requi2},
				{type:'upload',label:'R.I.B',name:'garant_rib', rules:requi2},
				{type:'upload',label:'Avis d\'impôt',name:'garant_impot', rules:requi2},
				{type:'upload',label:'Justificatif de domicile',extra:'quitance ou facture de moins de 3 mois',name:'garant_dom', rules:requi2},
				{type:'upload',label:'Contrat de travail',extra:'à defaut, une attestation employeur de moins de 3 mois',name:'garant_contrat', rules:requi2},
				{type:'upload',label:'3 fiches de paies',name:'garant_paie',  rules:requi2},
				{type:'upload',label:'Si chef d\'entreprise',name:'garant_kbis', extra:'Extrait KBIS, Bilan, déclaration de revenu'},
			], 
			    
		}
	]
	return type_dossier_arr[type_dossier]
}