import React from 'react';
import "antd/dist/antd.css";
import './App.css';
import Choix1 from './choix1.js'


import logonext from './next.svg'
// import logo from './logo3.svg'
// import logo2 from './logo2.svg'
// import logo1 from './logo1.svg'


import {BrowserRouter as Router, Switch, Route, Link} from "react-router-dom";
import {Row,Col,Space} from 'antd';


function App(props) {
	
	
	
	
    return <div className="App">
	

	

<Row gutter={[0,40]} style={{textAlign:'center',margin:'0'}}>
	
	<Col span={24} >
<Space size={20}>
				<img style={{height:140}} alt="" src={logonext}/>
				</Space>
			</Col>
</Row>

	<Router>
		<Switch>
			<Route path="/dossier/:uid" component={Choix1}/>
			
			<Route path="/" component={Choix1}/>
		</Switch>
	</Router>
	
	

<Row gutter={[0,40]} style={{margin:'0',padding:'64px 0',background:'#f7f7f7'}} justify="center">
	<Col span={24} style={{textAlign:'center'}}>
		<h4  style={{color:'#888'}}>AMI REUNION AGENCE DU HAUT COUSERANS - 7 Rue ROLAND GARROS - 97400 ST DENIS - RCS 485351183 Cusset - Garantie financière ALLIANZ IARD: Transaction 110 000 Gestion 400 000 Syndic 400 000 €
<br/>Carte pro : CPI 31012016000008355     déliverée par la CCI de la Réunion</h4>
	
<div style={{textAlign:'center'}}  >
			<a style={{color:'#999',fontSize:'90%'}} href="https://www.freepik.com/vectors/technology">Credit photo: www.freepik.com</a>
		</div>
	</Col>

</Row>



	
</div>;
}

export default App;
